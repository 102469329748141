import card1 from "../assets/img/card1.png";
import card2 from "../assets/img/card2.png";
import card3 from "../assets/img/card3.png";

export const categoryData = [
  {
    id: 1,
    img: card1,
    title: "Termiz muhandistlik-texnologiya",
    price: "240",
    calendar: "19:01 | 10-12-2021",
    time: "09:00 dan 18:00 gacha",
  },
  {
    id: 2,
    img: card2,
    title: "Termiz muhandistlik-texnologiya",
    price: "240",
    calendar: "19:01 | 10-12-2021",
    time: "09:00 dan 18:00 gacha",
  },
  {
    id: 3,
    img: card3,
    title: "Termiz muhandistlik-texnologiya",
    price: "240",
    calendar: "19:01 | 10-12-2021",
    time: "09:00 dan 18:00 gacha",
  },
  {
    id: 4,
    img: card1,
    title: "Termiz muhandistlik-texnologiya",
    price: "240",
    calendar: "19:01 | 10-12-2021",
    time: "09:00 dan 18:00 gacha",
  },
  {
    id: 5,
    img: card2,
    title: "Termiz muhandistlik-texnologiya",
    price: "240",
    calendar: "19:01 | 10-12-2021",
    time: "09:00 dan 18:00 gacha",
  },
  {
    id: 6,
    img: card3,
    title: "Termiz muhandistlik-texnologiya",
    price: "240",
    calendar: "19:01 | 10-12-2021",
    time: "09:00 dan 18:00 gacha",
  },
];
